@font-face {
	font-family: "ADAM";
	src: url("/adamcg.otf") format("opentype");
}

@font-face {
	font-family: "OPENSANS";
	src: url("/OpenSans-Regular.ttf") format("truetype");
}

body {
	font-family: "OPENSANS", sans-serif;
}

.nav-link.clicked {
	color: #fff !important;
}

.disableZoom {
	touch-action: manipulation;
}

.page-footer {
	/* width: 100%;
    position: fixed; */
	/* bottom: 0; */
	font-family: "ADAM", sans-serif;
	background-color: #051733;
	color: #eaeaea;
}
.button-primary {
	background-color: #96CEE4;
	border-color:#96CEE4;
}
.button {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.linkButton,
.linkButton:hover {
	color: #fff;
	text-decoration: none;
}

.App-header {
	background-color: #051733;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #051733;
}

.bg-theory {
	background-color: #051733;
	color: #eaeaea;
	font-family: "ADAM", sans-serif;
	margin-bottom: 10px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
